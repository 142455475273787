import Swal from 'sweetalert2'
import markModelJson from '@src/assets/data/mark-model-relations.json'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0
}

// ** Returns K format from a number
export const kFormatter = (num) => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
}
// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

//USED FOR CASE OF DATE BEING LIKE "2024-05-22T12:05:31.368011Z"
export const formatLongDate = (dateString) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

//** logic for handle delete confirmation modals
export const handleDeleteConfirmation = async ({
  title,
  confirmationText,
  confirmButtonText,
  onDeleteConfirmed,
  successMessage,
  errorMessage,
  history,
  successRedirect
}) => {
  const result = await Swal.fire({
    title,
    text: confirmationText,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText,
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-danger ms-1'
    },
    buttonsStyling: false
  })

  if (result.isConfirmed) {
    try {
      const response = await onDeleteConfirmed()

      if (response?.error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: errorMessage,
          customClass: {
            confirmButton: 'btn btn-danger'
          }
        })
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: successMessage,
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })

        if (successRedirect) {
          history.push(successRedirect)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export function cleanUpObject(inputObject) {
  if (typeof inputObject === 'object' && inputObject !== null) {
    const outputObject = {}

    for (const key in inputObject) {
      if (typeof inputObject[key] === 'object' && 'value' in inputObject[key]) {
        outputObject[key] = inputObject[key].value
      } else {
        outputObject[key] = cleanUpObject(inputObject[key])
      }
    }

    return outputObject
  } else {
    return inputObject
  }
}

export const markModelWithLowercaseKeys = Object.entries(markModelJson)
  .map(([key, value]) => [key.toLowerCase(), value])
  .reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value
    }),
    {}
  )

export const propOrDefault = (obj, propName) => {
  return obj && obj[propName] !== undefined ? obj[propName] : ''
}

export const propOrDefaultBool = (obj, propName) => {
  return obj && obj[propName] !== undefined ? obj[propName] : false
}

export const objectToQueryString = (obj = {}) => {
  if (Object.keys(obj).length === 0) {
    return ''
  }

  return Object.keys(obj)
    .map((key) => {
      let encodedKey = encodeURIComponent(key)
      let encodedValue = encodeURIComponent(obj[key])

      // Check if key or value needs to be preserved without encoding
      if (key === 'status') {
        encodedKey = key // Preserve key without encoding
        encodedValue = obj[key] // Preserve value without encoding
      }

      return `${encodedKey}=${encodedValue}`
    })
    .join('&')
}

export const formatSnakeCase = (input) => {
  // Split the string into words using underscores
  const words = input.split('_')

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  )

  // Join the words into a sentence
  const formattedString = capitalizedWords.join(' ')

  return formattedString
}

export const removeDecimals = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        obj[i] = removeDecimals(obj[i])
      }
    } else {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = removeDecimals(obj[key])
        }
      }
    }
  } else if (typeof obj === 'number' && Number.isFinite(obj)) {
    // Pretvori decimalni broj u celi broj
    obj = Math.floor(obj)
  }
  return obj
}

export const getCurrentDate = () => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Dodajemo 1 jer meseci kreću od 0
  const day = String(currentDate.getDate()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export const cyrillicToLatin = (tekst) => {
  const zamene = {
    А: 'A',
    Б: 'B',
    В: 'V',
    Г: 'G',
    Д: 'D',
    Ђ: 'Đ',
    Е: 'E',
    Ж: 'Ž',
    З: 'Z',
    И: 'I',
    Ј: 'J',
    К: 'K',
    Л: 'L',
    Љ: 'Lj',
    М: 'M',
    Н: 'N',
    Њ: 'Nj',
    О: 'O',
    П: 'P',
    Р: 'R',
    С: 'S',
    Т: 'T',
    Ћ: 'Ć',
    У: 'U',
    Ф: 'F',
    Х: 'H',
    Ц: 'C',
    Ч: 'Č',
    Џ: 'Dž',
    Ш: 'Š',
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    ђ: 'đ',
    е: 'e',
    ж: 'ž',
    з: 'z',
    и: 'i',
    ј: 'j',
    к: 'k',
    л: 'l',
    љ: 'lj',
    м: 'm',
    н: 'n',
    њ: 'nj',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    ћ: 'ć',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'č',
    џ: 'dž',
    ш: 'š'
  }

  const regexString = `[${Object.keys(zamene).join('')}]`
  const regex = new RegExp(regexString, 'g')

  return tekst.replace(regex, function (m) {
    return zamene[m]
  })
}

export const convertTimestamp = (timestamp) => {
  const date = new Date(timestamp)
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Adding 1 because months are zero-indexed
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return `${day}.${month}.${year} ${hours}:${minutes}`
}

export const formatNumberWithSpaces = (number) => {
  if (number) {
    // Convert the number to a string
    const [integerPart, decimalPart] = number.toString().split('.')

    // Format the integer part with spaces
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ' '
    )
    // Return the formatted number
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }
}

export const sortStrings = (stringsArray) => {
  if (!stringsArray?.length) return []
  const sortedArray = [...stringsArray].sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: 'base' })
  )
  return sortedArray
}

export const numberToText = (broj) => {
  const jedinice = [
    '',
    'jedan',
    'dva',
    'tri',
    'četiri',
    'pet',
    'šest',
    'sedam',
    'osam',
    'devet'
  ]
  const desetice = [
    '',
    'deset',
    'dvadeset',
    'trideset',
    'četrdeset',
    'pedeset',
    'šezdeset',
    'sedamdeset',
    'osamdeset',
    'devedeset'
  ]
  const brojeviDo20 = [
    '',
    'jedan',
    'dva',
    'tri',
    'četiri',
    'pet',
    'šest',
    'sedam',
    'osam',
    'devet',
    'deset',
    'jedanaest',
    'dvanaest',
    'trinaest',
    'četrnaest',
    'petnaest',
    'šesnaest',
    'sedamnaest',
    'osamnaest',
    'devetnaest'
  ]
  const stotine = [
    '',
    'sto',
    'dvesta',
    'trista',
    'četiristo',
    'petsto',
    'šesto',
    'sedamsto',
    'osamsto',
    'devetsto'
  ]

  if (broj < 20) {
    return brojeviDo20[broj]
  } else if (broj < 100) {
    const desetica = Math.floor(broj / 10)
    const jedinica = broj % 10
    return desetice[desetica] + (jedinica > 0 ? jedinice[jedinica] : '')
  } else if (broj < 1000) {
    const stotina = Math.floor(broj / 100)
    const ostatak = broj % 100
    return stotine[stotina] + (ostatak > 0 ? brojUSlova(ostatak) : '')
  } else if (broj < 10000) {
    const hiljada = Math.floor(broj / 1000)
    const ostatak = broj % 1000
    const hiljadaStr =
      hiljada === 1 ? 'hiljadu' : `${jedinice[hiljada]} hiljade`
    return hiljadaStr + (ostatak > 0 ? brojUSlova(ostatak) : '')
  } else {
    return 'Broj je prevelik'
  }
}
export const searchArrayByColumns = (array, columns, searchTerm) => {
  function getValueByPath(obj, path) {
      const keys = path.split('.');
      for (const key of keys) {
          if (obj && typeof obj === 'object' && key in obj) {
              obj = obj[key];
          } else {
              return undefined;
          }
      }
      return obj;
  }
  const filteredArray = columns.filter(obj => {
    return obj.searchable; 
  });
  console.log('filtered:', filteredArray)

  const resultArray = filteredArray.map(obj => {
    if (Array.isArray(obj.searchable)) {
      return obj.searchable;
    } else {
      return [obj.searchable];
    }
  }).flat();
  return array?.filter(item => {
      return resultArray.some(column => {
          const value = getValueByPath(item, column);
          if (String(value)?.toLowerCase().includes(searchTerm.toLowerCase())) {
              return true;
          }
          return false;
      });
  });
}
export function validateVin(vin) {
  const transliterationMap = {
    A: 1,
    B: 2,
    C: 3,
    D: 4, 
    E: 5, 
    F: 6, 
    G: 7,
    H: 8,
    J: 1,
    K: 2,
    L: 3,
    M: 4,
    N: 5,
    P: 7,
    R: 9,
    S: 2,
    T: 3,
    U: 4, 
    V: 5, 
    W: 6, 
    X: 7, 
    Y: 8,
    Z: 9,
    1: 1,
    2: 2, 
    3: 3, 
    4: 4, 
    5: 5, 
    6: 6,
    7: 7,
    8: 8,
    9: 9, 
    0: 0
};

  if (vin.length !== 17) {
      return false;
  }
  vin = vin.toUpperCase();
  
  for (let i = 0; i < vin.length; i++) {
      const char = vin[i];
      if (!transliterationMap.hasOwnProperty(char)) {
          return false; 
      }
  }
  return true
}