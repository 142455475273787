// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { objectToQueryString } from '../../../utility/Utils'
// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

export const getAllInvoicesV1 = createAsyncThunk(
  'appFinance/getAllInvoicesV1',
  async (queryParams = { page_size: 10, page: 1 }) => {
    const queryString = objectToQueryString(queryParams)
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/finance/v2/client/invoice?${queryString}`
      )
      return {
        data: response.data.results,
        allData: response.data.results,
        count: response.data.count,
        totalPages: Math.ceil(response.data.count / queryParams.page_size),
        vehicles: response.data.results
      }
    } catch (error) {
      console.log(error)
    }
  }
)
export const getAllInvoices = createAsyncThunk(
  'appFinance/getAllInvoices',
  async () => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/finance/client/invoice`
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const getInvoice = createAsyncThunk(
  'appVehicle/getInvoice',
  async (data) => {
    try {
      const response = await authAxios.get(`${apiEndpoint}/finance/invoice/${data.invoice}`) 
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const getInvoices = createAsyncThunk(
  'appFinance/getInvoices',
  async () => {
    try {
      const { data: clients } = await authAxios.get(
        `${apiEndpoint}/partner/client`
      )
      const invoices = await Promise.all(
        clients.map((client) => {
          return authAxios.get(
            `${apiEndpoint}/finance/client/${client.id}/invoice`
          )
        })
      )

      return invoices.map((i) => i.data).flat()
    } catch (error) {
      console.log(error)
    }
  }
)

export const deletePayment = createAsyncThunk(
  'appFinance/deletePayment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/finance/invoice/${data.invoiceId}/payment/${data.paymentId}`
      );
      if (response.status === 200) return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteBill = createAsyncThunk(
  'appFinance/deleteBill',
  async (billId, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/finance/bill/${billId}`
      )
      return billId
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)
export const deleteBillPayment = createAsyncThunk(
  'appFinance/deleteBillPayment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/finance/bill/${data.billId}/payment/${data.paymentId}`
      );
      return paymentId
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)
export const deleteInvoice = createAsyncThunk(
  'appFinance/deleteInvoice',
  async (id) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/finance/invoice/${id}`
      );
      return id;
    } catch (error) {
      console.log(error)
      throw error
    }
  }
);
export const addInvoice = createAsyncThunk(
  'appFinance/addInvoice',
  async (data) => {
    try {
      const { data: invoice } = await authAxios.post(
        `${apiEndpoint}/finance/client/${data.clientId}/invoice`,
        data.invoice
      )
      return invoice
    } catch (error) {
      console.log(error)
    }
  }
)

export const getBills = createAsyncThunk('appFinance/getBills', async () => {
  try {
    const { data: vendors } = await authAxios.get(
      `${apiEndpoint}/partner/vendor`
    )
    const bills = await Promise.all(
      vendors.map((vendor) => {
        return authAxios.get(`${apiEndpoint}/finance/vendor/${vendor.id}/bill`)
      })
    )

    return bills.map((i) => i.data).flat()
  } catch (error) {
    console.log(error)
  }
})

export const getAllBills = createAsyncThunk(
  'appFinance/getAllBills',
  async () => {
    try {
      const response = await authAxios.get(`${apiEndpoint}/finance/vendor/bill`)

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getBill = createAsyncThunk(
  'appFinance/getBill',
  async (id) => {
    try {
      const response = await authAxios.get(`${apiEndpoint}/finance/bill/${id}`)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const addBill = createAsyncThunk('appFinance/addBill', async (data) => {
  try {
    const { data: bill } = await authAxios.post(
      `${apiEndpoint}/finance/vendor/${data.vendorId}/bill`,
      data.bill
    )
    return bill
  } catch (error) {
    console.log(error)
  }
})

export const addBillPayment = createAsyncThunk(
  'appFinance/addBillPayment',
  async (data) => {
    try {
      const { data: payment } = await authAxios.post(
        `${apiEndpoint}/finance/bill/${data.billId}/payment`,
        data.payment
      )
      return payment
    } catch (error) {
      console.log(error)
    }
  }
)

export const getPurchaseInformations = createAsyncThunk(
  'appFinance/getPurchaseInformations',
  async (vehicleId) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${vehicleId}/purches`
      )
      return response
    } catch (error) {
      console.log(error)
    }
  }
)

export const getIncomeInformations = createAsyncThunk(
  'appFinance/getIncomeInformations',
  async (vehicleId) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${vehicleId}/earnings`
      )
      
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getAllSourceOfFinance = createAsyncThunk(
  'formData/getAllSourceOfFinance',
  async () => {
    try {
      const response = await axios.get(`${apiEndpoint}/vehicle/finance_source`)
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }
)

export const appFinanceSlice = createSlice({
  name: 'appFinance',
  initialState: {
    invoices: [],
    bills: [],
    purchaseInformations: [],
    incomeInformations: {},
    allSourceOfFinance: [],
    selectedInvoice: [],
    selectedBill: [],
    data: [],
    allData: [],
    total: 0,
    params: {},
    count: 0,
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllInvoicesV1.pending, (state, action) => { 
        state.isLoading = true
      })
      .addCase(getAllInvoicesV1.fulfilled, (state, action) => {
        try {
          state.data = action.payload.data
          state.allData = action.payload.allData
          state.total = action.payload.totalPages
          state.count = action.payload.count
          state.params = action.payload.params
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      })
      .addCase(getPurchaseInformations.fulfilled, (state, action) => {
        try {
          state.purchaseInformations = action.payload.data
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getIncomeInformations.fulfilled, (state, action) => {
        try {
          state.incomeInformations = action.payload
          state.isLoading = false
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getIncomeInformations.rejected, (state, action) => {
        try {
          state.isLoading = false
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getIncomeInformations.pending, (state, action) => {
        try {
          state.isLoading = true
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        try {
          state.selectedInvoice = action.payload
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        try {
          state.invoices = action.payload
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getAllInvoices.fulfilled, (state, action) => {
        try {
          state.invoices = action.payload
        } catch (error) {
          console.log(error)
        } finally {
          state.isLoading = false
        }
      })
      .addCase(getAllInvoices.pending, (state, action) => {
        try {
          state.isLoading = true
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        try {
          state.allData = state.allData.filter(
            (invoice) => invoice.id !== action.payload
          )
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deleteBill.fulfilled, (state, action) => {
        try {
          state.bills = state.bills.filter(
            (bill) => bill.id !== action.payload
          )
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(addInvoice.fulfilled, (state, action) => {
        try {
          state.invoices = [...state.invoices, action.payload]
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        const invoice = action.payload.invoice
        const updatedPayments = invoice.invoice_payments.filter(payment => payment.id !== action.payload.paymentId);
        const updatedInvoice = {
          ...invoice, 
          invoice_payments: updatedPayments 
        };
        state.selectedInvoice = updatedInvoice
      })
      .addCase(deleteBillPayment.fulfilled, (state, action) => {
        const updatedPaymentList = state.selectedBill.bill_payments.filter(
          (payment) => payment.id !== action.payload
        )
        state.selectedBill = {...state.selectedBill, bill_payments: updatedPaymentList}
      })
      .addCase(getBills.fulfilled, (state, action) => {
        try {
          state.bills = action.payload
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getAllBills.fulfilled, (state, action) => {
        try {
          state.bills = action.payload
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getBill.pending, (state, action) => {
        try {
          state.isLoading = true
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getBill.fulfilled, (state, action) => {
        try {
          state.selectedBill = action.payload
          state.isLoading = false
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(addBill.fulfilled, (state, action) => {
        try {
          state.bills = [...state.bills, action.payload]
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(addBillPayment.fulfilled, (state, action) => {
        try {
          state.selectedBill.bill_payments = [...state.selectedBill.bill_payments, action.payload]
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getAllSourceOfFinance.fulfilled, (state, action) => {
        try {
          state.allSourceOfFinance = action.payload
        } catch (error) {
          console.log(error)
        }
      })
  }
})

export default appFinanceSlice.reducer
