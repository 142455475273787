import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  COMPANY_DEFAULT_VALUES,
  INDIVIDUAL_DEFAULT_VALUES,
  personInChargeType
} from '../DefaultValues'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'
import { objectToQueryString } from '../../../utility/Utils'
export const apiEndpoint = `${BASE_URL}/api`
const authAxios = axios
const INITIAL_STATE = {
  // GENERAL
  files: [],
  selectedClient: {},
  selectedClientData: {},
  importedData: [],
  importedDataAPR: [],
  allGlobalVendors: [],

  // PRIVATE INDIVIDUAL
  individualData: INDIVIDUAL_DEFAULT_VALUES,
  isDropzoneVisible: { isVisible: true },
  // COMPANY
  companyData: COMPANY_DEFAULT_VALUES,
  contactPersons: [],
  vendorData: [],
  bankAccounts: [],
  configuration: {
    isLoading: false,
    reminder: null,
    eventTyres: null
  },

  personsInCharge: {
    LEGAL_REPRESENTATIVE: [],
    NONE: [],
    DIRECTOR: [],
    EXECUTIVE_BOARD: [],
    BOARD_OF_DIRECTORS: [],
    PROCURATOR: []
  },
  data: [],
  allData: [],
  total: 0,
  count: 0,
  params: {},
  isLoading: false
}

export const getAllGlobalVendors = createAsyncThunk(
  'formData/getAllGlobalVendors',
  async (queryParams) => {
    const queryString = objectToQueryString(queryParams)
    try {
      const response = await axios.get(
        `${apiEndpoint}/partner/globalvendor?${queryString}`
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getAllClients = createAsyncThunk(
  'formData/getAllClients',
  async () => {
    try {
      const { data: clients } = await axios.get(`${apiEndpoint}/partner/client`)

      return { clients }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getClients = createAsyncThunk(
  'appVehicle/getClients',
  async (queryParams = { page_size: 10, page: 1 }) => {
    const queryString = objectToQueryString(queryParams)
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/partner/v2/client?${queryString}`
      )
      return {
        data: response.data.results,
        allData: response.data.results,
        count: response.data.count,
        totalPages: Math.ceil(response.data.count / queryParams.page_size),
        vehicles: response.data.results
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const addCompany = createAsyncThunk(
  'formData/addCompany',
  async (primary_id) => {
    try {
      const resp = await axios.get(
        `${apiEndpoint}/partner/apr_request/${primary_id}`
      )
      return resp.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const fetchVendorData = createAsyncThunk(
  'formData/fetchVendorData',
  async () => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/configuration/partner/vendor`
      )
      return response.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const createVendorData = createAsyncThunk(
  'formData/createVendorData',
  async (data) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/configuration/partner/vendor`,
        data
      )
      return response.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const getRemindersConfiguration = createAsyncThunk(
  'formData/getRemindersConfiguration',
  async () => {
    try {
      const response = await axios.get(`${apiEndpoint}/configuration/remainder`)
      return response.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const updateRemindersConfiguration = createAsyncThunk(
  'formData/updateRemindersConfiguration',
  async (data) => {
    try {
      const response = await axios.put(
        `${apiEndpoint}/configuration/remainder`,
        data
      )
      return response.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const getCascoConfiguration = createAsyncThunk(
  'formData/getCascoConfiguration',
  async () => {
    try {
      const response = await axios.get(`${apiEndpoint}/configuration/casco`)
      return response.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const updateCascoConfiguration = createAsyncThunk(
  'formData/updateCascoConfiguration',
  async (data) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/configuration/casco`,
        data
      )
      return response.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const getEventTyreConfiguration = createAsyncThunk(
  'formData/getEventTyreConfiguration',
  async () => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/configuration/event/tyres`
      )
      return response.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const updateEventTyreConfiguration = createAsyncThunk(
  'formData/updateEventTyreConfiguration',
  async (data) => {
    try {
      const response = await axios.put(
        `${apiEndpoint}/configuration/event/tyres`,
        data
      )
      return response.data
    } catch (err) {
      console.error(err)
    }
  }
)

export const addNewLocation = createAsyncThunk(
  'formData/addNewLocation',
  async ({ data, partnerType }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/partner/${partnerType}/${data.partner}/location`,
        data
      )
      return {
        data: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getLocations = createAsyncThunk(
  'formData/getLocations',
  async ({ partnerId, partnerType }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/partner/${partnerType}/${partnerId}/location`
      )
      return response.data
    } catch (error) {
      console.error('Error fetching locations:', error.message)
    }
  }
)

export const updateClient = createAsyncThunk(
  'formData/updateClient',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/partner/client/${data.id}`,
        data
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const updateVendor = createAsyncThunk(
  'formData/updateVendor',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/partner/vendor/${data.id}`,
        data
      )

      return response
    } catch (error) {
      console.log(error)
    }
  }
)

export const getInfoById = createAsyncThunk(
  'formData/getInfoById',
  async (data) => {
    try {
      const response = await axios.post(`${apiEndpoint}/partner/nbs`, {
        PIB: data.PIB,
        MBR: data.MBR
      })
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

export const deleteLocation = createAsyncThunk(
  'formData/deleteLocation',
  async ({ partnerId, locationId, partnerType }) => {
    try {
      await axios.delete(
        `${apiEndpoint}/partner/${partnerType}/${partnerId}/location/${locationId}`
      )
      return locationId
    } catch (error) {
      console.error('Error deleting location:', error.message)
    }
  }
)

export const addClientContactPerson = createAsyncThunk(
  'formData/addClientContactPerson',
  async ({ data, clientId }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/partner/client/${clientId}/representative`,
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const addVendorContactPerson = createAsyncThunk(
  'formData/addVendorContactPerson',
  async ({ data, vendorId }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/partner/vendor/${vendorId}/representative`,
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const addClientRepresentative = createAsyncThunk(
  'formData/addClientRepresentative',
  async ({ person, data, clientId }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/partner/client/${clientId}/representative`,
        {
          ...data,
          is_legal_representative: !!person
            ?.toLowerCase()
            ?.includes('representatives'),
          is_ceo: !!person?.toLowerCase()?.includes('directors')
        }
      )
      return {
        data: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getClientRepresentative = createAsyncThunk(
  'formData/getClientRepresentative',
  async ({ partnerId, representative_type }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/partner/client/${partnerId}/representative${
          representative_type
            ? `?representative_type=${representative_type}`
            : ''
        }`
      )
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }
)

export const getContactPerson = createAsyncThunk(
  'formData/getContactPerson',
  async ({ partnerId }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/partner/client/${partnerId}/representative?representative_type=CONTACT_PERSON`
      )
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }
)
export const deleteClient = createAsyncThunk(
  'formData/deleteClient',
  async (id) => {
    console.log(id)
    try {
      await axios.delete(`${apiEndpoint}/partner/client/${id}`)
      return id
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const deleteClientRepresentative = createAsyncThunk(
  'formData/deleteClientRepresentative  ',
  async ({ partnerId, representative_id }) => {
    try {
      await axios.delete(
        `${apiEndpoint}/partner/client/${partnerId}/representative/${representative_id}`
      )
      return representative_id
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }
)

export const getVendorRepresentative = createAsyncThunk(
  'formData/getVendorRepresentative',
  async ({ partnerId, representative_type }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/partner/vendor/${partnerId}/representative${
          representative_type
            ? `?representative_type=${representative_type}`
            : ''
        }`
      )
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }
)

export const deleteVendorRepresentative = createAsyncThunk(
  'formData/deleteVendorRepresentative  ',
  async ({ partnerId, representative_id }) => {
    try {
      await axios.delete(
        `${apiEndpoint}/partner/vendor/${partnerId}/representative/${representative_id}`
      )
      return representative_id
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }
)

export const addVendorRepresentativePreview = createAsyncThunk(
  'formData/addVendorRepresentativePreview',
  async ({ person, data, vendorId }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/partner/vendor/${vendorId}/representative`,
        {
          ...data,
          is_legal_representative: !!person
            ?.toLowerCase()
            ?.includes('representatives'),
          is_ceo: !!person?.toLowerCase()?.includes('directors')
        }
      )
      return {
        data: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const refreshClientBankAccounts = createAsyncThunk(
  'formData/refreshClientBankAccounts',
  async ({ clientId }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/partner/client/${clientId}/bank`,
        {}
      )
      return {
        data: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)
export const refreshVendorBankAccounts = createAsyncThunk(
  'formData/refreshVendorBankAccounts',
  async ({ vendorId }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/partner/vendor/${vendorId}/bank`,
        {}
      )
      return {
        data: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)
export const getClient = createAsyncThunk(
  'formData/getClient',
  async ({ partnerId }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/partner/client/${partnerId}`
      )
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }
)
export const getClientData = createAsyncThunk(
  'formData/getClientData',
  async ({ partnerId }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/partner/client/${partnerId}/data`
      )
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }
)

export const uploadClientFiles = createAsyncThunk(
  'formData/uploadClientFiles',
  async ({ data, clientId }) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      const response = await authAxios.post(
        `${apiEndpoint}/partner/client/${clientId}/file`,
        data,
        config
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getClientFiles = createAsyncThunk(
  'appVehicle/getClientFiles',
  async ({ clientId }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/partner/client/${clientId}/file`
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const removeClientFiles = createAsyncThunk(
  'formData/removeClientFiles',
  async ({ partnerId, fileId }) => {
    try {
      await axios.delete(
        `${apiEndpoint}/partner/client/${partnerId}/file/${fileId}`
      )
      return fileId
    } catch (error) {
      console.error('Error deleting file:', error.message)
    }
  }
)

export const uploadVendorFiles = createAsyncThunk(
  'formData/uploadVendorFiles',
  async ({ data, vendorId }) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      const response = await authAxios.post(
        `${apiEndpoint}/partner/vendor/${vendorId}/file`,
        data,
        config
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getVendorFiles = createAsyncThunk(
  'appVehicle/getVendorFiles',
  async ({ vendorId }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/partner/vendor/${vendorId}/file`
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const removeVendorFiles = createAsyncThunk(
  'formData/removeVendorFiles',
  async ({ partnerId, fileId }) => {
    try {
      await axios.delete(
        `${apiEndpoint}/partner/vendor/${partnerId}/file/${fileId}`
      )
      return fileId
    } catch (error) {
      console.error('Error deleting file:', error.message)
    }
  }
)

const formDataSlice = createSlice({
  name: 'formData',
  initialState: INITIAL_STATE,
  reducers: {
    // GENERAL
    resetState: (state) => INITIAL_STATE,

    // PRIVATE INDIVIDUAL
    addIndividual: (state, action) => ({
      ...state,
      individualData: { ...state.individualData, ...action.payload }
    }),
    toggleDropzoneVisible: (state) => {
      const isVisible = !state.isDropzoneVisible.isVisible
      return { ...state, isDropzoneVisible: { isVisible } }
    },

    // COMPANY
    // addCompany: (state, action) => ({
    //   ...state,
    //   companyData: { ...state.companyData, ...action.payload }
    // }),
    addContactPerson: (state, action) => ({
      ...state,
      contactPersons: [...state.contactPersons, action.payload]
    }),

    addBankAccount: (state, action) => ({
      ...state,
      bankAccounts: [...state.bankAccounts, action.payload]
    }),

    deleteBankAccount: (state, action) => {
      const filteredBankAccounts = state.bankAccounts.filter(
        (account) => account.id !== action.payload
      )
      return { ...state, bankAccounts: filteredBankAccounts }
    },

    // COMPANY PERSON IN CHARGE
    addVendorRepresentative: (state, action) => {
      const { person } = action.payload
      const { data } = action.payload
      const id = state?.personsInCharge[person]?.length
      return {
        ...state,
        personsInCharge: {
          ...state.personsInCharge,
          [person]: [...state.personsInCharge[person], { ...data, id }]
        }
      }
    },
    deleteRepresentative: (state, action) => {
      const { person } = action.payload
      const { id } = action.payload
      const filtered = state.personsInCharge[person].filter(
        (person) => person.id !== id
      )
      return {
        ...state,
        personsInCharge: {
          ...state.personsInCharge,
          [person]: filtered
        }
      }
    },
    addFiles: (state, action) => ({
      ...state,
      files: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendorData.fulfilled, (state, action) => {
        // Update the global state with the fetched data
        state.vendorData = action.payload
      })
      .addCase(uploadClientFiles.fulfilled, (state, action) => {
        state.files = [...state.files, action.payload]
      })
      .addCase(getClientFiles.fulfilled, (state, action) => {
        state.files = action.payload
      })
      .addCase(uploadVendorFiles.fulfilled, (state, action) => {
        state.files = [...state.files, action.payload]
      })
      .addCase(getVendorFiles.fulfilled, (state, action) => {
        state.files = action.payload
      })
      .addCase(getInfoById.fulfilled, (state, action) => {
        state.importedData = action.payload
      })
      .addCase(refreshClientBankAccounts.fulfilled, (state, action) => {
        state.importedData.data = action.payload.data.bank_accounts
      })
      .addCase(refreshVendorBankAccounts.fulfilled, (state, action) => {
        state.importedData.data = action.payload.data.bank_accounts
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        /*const newCompanyData = {
          ...state.companyData,
          ...action.payload
        }
        state.companyData = newCompanyData

        state.personsInCharge.legalRepresentatives = [
          ...action.payload?.legal_representatives
        ].map((rep, ind) => {
          return { ...rep, id: ind }
        })
        state.personsInCharge.otherRepresentatives = [
          ...action.payload?.other_representatives
        ].map((rep, ind) => {
          return { ...rep, id: ind }
        })
        state.personsInCharge.directors = [...action.payload?.directors].map(
          (rep, ind) => {
            return { ...rep, id: ind }
          }
        )
        state.personsInCharge.executiveBoard = [
          ...action.payload?.executive_board
        ].map((rep, ind) => {
          return { ...rep, id: ind }
        })
        state.personsInCharge.procurators = [
          ...action.payload?.procurators
        ].map((rep, ind) => {
          return { ...rep, id: ind }
        })
        state.personsInCharge.boardOfDirectors = [
          ...action.payload?.board_of_directors
        ].map((rep, ind) => {
          return { ...rep, id: ind }
        })*/

        state.importedDataAPR = action.payload.data
      })
      .addCase(addNewLocation.fulfilled, (state, action) => {
        try {
          const newLocationData = action.payload.data
          state.vendorData.push(newLocationData)
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.vendorData = action.payload
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.selectedClient = action.payload
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        const deletedLocationId = action.payload
        state.vendorData = state.vendorData.filter(
          (location) => location.id !== deletedLocationId
        )
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        try {
          state.clients = action.payload.clients
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getClients.fulfilled, (state, action) => {
        try {
          state.data = action.payload.data
          state.allData = action.payload.allData
          state.total = action.payload.totalPages
          state.count = action.payload.count
          state.params = action.payload.params
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      })
      .addCase(getClients.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(getClients.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getClient.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getClient.fulfilled, (state, action) => {
        try {
          state.selectedClient = action.payload
          state.isLoading = false
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getClientData.fulfilled, (state, action) => {
        try {
          state.selectedClientData = action.payload
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        try {
          const filtered = state.allData.filter(
            (client) => client.id !== action.payload
          )
          state.allData = [...filtered]
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(getClientRepresentative.fulfilled, (state, action) => {
        const data = action.payload
        if (data?.length > 0) {
          const representativeType = data[0].representative_type
          state.personsInCharge[representativeType] = data
        }
      })
      .addCase(getContactPerson.fulfilled, (state, action) => {
        state.contactPersons = action.payload
      })
      .addCase(deleteClientRepresentative.fulfilled, (state, action) => {
        const filteredContactPersons = state.contactPersons.filter(
          (person) => person.id !== action.payload
        )
        return { ...state, contactPersons: filteredContactPersons }
      })
      .addCase(deleteVendorRepresentative.fulfilled, (state, action) => {
        const filteredContactPersons = state.contactPersons.filter(
          (person) => person.id !== action.payload
        )
        return { ...state, contactPersons: filteredContactPersons }
      })
      .addCase(getVendorRepresentative.fulfilled, (state, action) => {
        const data = action.payload
        if (data?.length > 0) {
          const representativeType = data[0].representative_type
          state.personsInCharge[representativeType] = data
        }
      })
      .addCase(getAllGlobalVendors.fulfilled, (state, action) => {
        state.allGlobalVendors = action.payload
      })
      .addCase(addClientContactPerson.fulfilled, (state, action) => {
        state.contactPersons.push(action.payload)
      })
      .addCase(addVendorContactPerson.fulfilled, (state, action) => {
        state.contactPersons.push(action.payload)
      })
      .addCase(addVendorRepresentativePreview.fulfilled, (state, action) => {
        const newRepresentative = action.payload.data
        const representativeType = newRepresentative.representative_type
        state.personsInCharge[representativeType].push(newRepresentative)
      })
      .addCase(addClientRepresentative.fulfilled, (state, action) => {
        const newRepresentative = action.payload.data
        const representativeType = newRepresentative.representative_type
        state.personsInCharge[representativeType].push(newRepresentative)
      })
      .addCase(getRemindersConfiguration.fulfilled, (state, action) => {
        state.configuration.reminder = action.payload[0]
        state.configuration.isLoading = false
      })
      .addCase(getRemindersConfiguration.rejected, (state, action) => {
        state.configuration.reminder = nulll
        state.configuration.isLoading = false
      })
      .addCase(getRemindersConfiguration.pending, (state, action) => {
        state.configuration.isLoading = true
      })

      .addCase(getCascoConfiguration.fulfilled, (state, action) => {
        state.configuration.casco = action.payload[0]
        state.configuration.isLoading = false
      })
      .addCase(getCascoConfiguration.rejected, (state) => {
        state.configuration.casco = null
        state.configuration.isLoading = false
      })
      .addCase(getCascoConfiguration.pending, (state) => {
        state.configuration.isLoading = true
      })

      .addCase(getEventTyreConfiguration.fulfilled, (state, action) => {
        state.configuration.eventTyres = action.payload
        state.configuration.isLoading = false
      })
      .addCase(getEventTyreConfiguration.rejected, (state) => {
        state.configuration.eventTyres = null
        state.configuration.isLoading = false
      })
      .addCase(getEventTyreConfiguration.pending, (state) => {
        state.configuration.isLoading = true
      })
      .addCase(createVendorData.fulfilled, (state, action) => {
        state.vendorData = [...state.vendorData, action.payload]
        state.configuration.isLoading = false
      })
      .addCase(createVendorData.pending, (state) => {
        state.configuration.isLoading = true
      })
  }
})

export const {
  addIndividual,
  toggleDropzoneVisible,
  // addCompany,
  addContactPerson,
  deleteContactPerson,
  resetState,
  addVendorRepresentative,
  deleteRepresentative,
  addFiles,
  addBankAccount,
  deleteBankAccount
} = formDataSlice.actions

export default formDataSlice.reducer
